import axios from 'axios'
import { META_CAPI_EVENTS_URL_WITH_CONFIG_ID } from 'composable/components/general/constants'
import { getCookieByName } from 'composable/components/mystore/utils'

export const getCAPIrootEventsProps = (eventName: string) => {
  const eventObject = {
    event_name: eventName,
    event_id: eventName + new Date().getTime(),
    event_time: Math.floor(Date.now() / 1000),
    event_source_url: window.location.href,
    user_data: {
      fbp: getCookieByName('_fbp') ?? '',
      fbc: getCookieByName('_fbc') ?? '',
    },
  }
  const partnerId = getCookieByName('_lr_fb_env')
  if (!!partnerId && partnerId?.length > 0) {
    eventObject.user_data['partner_id'] = partnerId
  }

  return eventObject
}

export const callCAPIEventForPageView = (countryCode: string) => {
  const eventData = {
    events: [
      {
        ...getCAPIrootEventsProps('PageView'),
        customer_information: [
          {
            key: 'country',
            value: countryCode,
          },
        ],
      },
    ],
    partner_agent: 'liveramp',
  }
  if (process.env.NODE_ENV !== 'development') {
    addCAPIFbEvent(eventData)
  }
}

export const addCAPIFbEvent = (data) => {
  try {
    axios.post(META_CAPI_EVENTS_URL_WITH_CONFIG_ID, data)
  } catch (e) {
    console.error('capi error::', e)
  }
}
